export const dataFile = [
  {
    name: 'Инфо',
    extension: 'pdf',
  },
  {
    name: 'Список',
    extension: 'docx',
  },
];

export const dataImage = [
  'https://fikiwiki.com/uploads/posts/2022-02/1644855639_6-fikiwiki-com-p-kartinki-khd-kachestva-6.jpg',
  'https://w.forfun.com/fetch/b3/b33164ded5864ed5ba7728b3c0611181.jpeg',
  'https://catherineasquithgallery.com/uploads/posts/2021-02/1612872204_51-p-kartinki-na-krasnom-fone-dlya-telefona-68.jpg',
];
