import { TVariable, TName } from '../../../services/types/builder';

export const storeOfVariables: TVariable[] = [
  {
    id: '1',
    name: 'mock',
    value: 'unknown',
  },
];

export const namesOfBlocks: TName[] = [];

export default {};
