const mockChannelLink = 'https://t.me/botname?start=777';
const mockChannelName = 'ChannelName';
const mockAccessKey = '1842016250:AAF8uWUV1wE9B8XxZvfbmRiZkmh1tWXu-Ns';
const mockNotifyLink = '/notify 60a280e3f7b15d37975ccb69';
const mockDisableNotufyLink = '/disable_notify 60a280e3f7b15d37975ccb69';

export {
  mockChannelLink,
  mockChannelName,
  mockAccessKey,
  mockNotifyLink,
  mockDisableNotufyLink,
};
