import {
  inputCell,
  switcherCell,
} from '../../components/table-cells/table-cells';

export const headStyle = {
  border: 'none',
  backgroundColor: '#ECEFFF',
  padding: '0',
  borderRadius: '0',
  ':last-of-type': {
    borderRadius: '0 10px 10px 0',
  },
};

export const rowStyleRef = {
  height: '72px',
  borderBottom: '.5px #CCD4E0 solid',
  ':hover': {
    backgroundColor: '#F8F9FB',
  },
  cursor: 'pointer',
};

export const cellStyle = {
  border: 'none',
  padding: '0',
  boxSizing: 'border-box',
};

export const tariffsRows = [
  {
    id: 1,
    name: 'Демо',
    price: '400',
    bots: 10,
    followers: 600,
    duration: '7D',
    start: false,
    tel: '111223333',
    one: 'one',
    two: 'two',
  },
  {
    id: 2,
    name: 'Акция',
    price: '50',
    bots: 10,
    followers: 3500,
    duration: '1M',
    start: false,
  },
  {
    id: 3,
    name: 'Бесплатный',
    price: '100',
    bots: 24,
    followers: 4013,
    duration: '7D',
    start: true,
  },
  {
    id: 4,
    name: 'Бизнес',
    price: '250',
    bots: 15,
    followers: 2000,
    duration: '3W',
    start: true,
  },
  {
    id: 5,
    name: 'Старт',
    price: '120',
    bots: 32,
    followers: 223,
    duration: '1M',
    start: false,
  },
  {
    id: 6,
    name: 'Elite',
    price: '33',
    bots: 11,
    followers: 780,
    duration: '7D',
    start: false,
  },
  {
    id: 7,
    name: 'Комфорт +',
    price: '900',
    bots: 10,
    followers: 5775,
    duration: '5D',
    start: true,
  },
  {
    id: 8,
    name: 'Старт',
    price: '120',
    bots: 32,
    followers: 223,
    duration: '1M',
    start: false,
  },
  {
    id: 9,
    name: 'Elite',
    price: '33',
    bots: 11,
    followers: 780,
    duration: '7D',
    start: false,
  },
  {
    id: 10,
    name: 'Комфорт +',
    price: '900',
    bots: 10,
    followers: 5775,
    duration: '5D',
    start: true,
  },
  {
    id: 11,
    name: 'Старт',
    price: '120',
    bots: 32,
    followers: 223,
    duration: '1M',
    start: false,
  },
  {
    id: 12,
    name: 'Elite',
    price: '33',
    bots: 11,
    followers: 780,
    duration: '7D',
    start: false,
  },
  {
    id: 13,
    name: 'Комфорт +',
    price: '900',
    bots: 10,
    followers: 5775,
    duration: '5D',
    start: true,
  },
  {
    id: 14,
    name: 'Старт',
    price: '120',
    bots: 32,
    followers: 223,
    duration: '1M',
    start: false,
  },
  {
    id: 15,
    name: 'Elite',
    price: '33',
    bots: 11,
    followers: 780,
    duration: '7D',
    start: false,
  },
  {
    id: 16,
    name: 'Комфорт +',
    price: '900',
    bots: 10,
    followers: 5775,
    duration: '5D',
    start: true,
  },

  {
    id: 17,
    name: 'Старт',
    price: '120',
    bots: 32,
    followers: 223,
    duration: '1M',
    start: false,
  },
  {
    id: 18,
    name: 'Elite',
    price: '33',
    bots: 11,
    followers: 780,
    duration: '7D',
    start: false,
  },
  {
    id: 19,
    name: 'Комфорт +',
    price: '900',
    bots: 10,
    followers: 5775,
    duration: '5D',
    start: true,
  },
  {
    id: 20,
    name: 'Старт',
    price: '120',
    bots: 32,
    followers: 223,
    duration: '1M',
    start: false,
  },
  {
    id: 21,
    name: 'Elite',
    price: '33',
    bots: 11,
    followers: 780,
    duration: '7D',
    start: false,
  },
  {
    id: 22,
    name: 'Комфорт +',
    price: '900',
    bots: 10,
    followers: 5775,
    duration: '5D',
    start: true,
  },
  {
    id: 23,
    name: 'Старт',
    price: '120',
    bots: 32,
    followers: 223,
    duration: '1M',
    start: false,
  },
  {
    id: 24,
    name: 'Elite',
    price: '33',
    bots: 11,
    followers: 780,
    duration: '7D',
    start: false,
  },
  {
    id: 25,
    name: 'Комфорт +',
    price: '900',
    bots: 10,
    followers: 5775,
    duration: '5D',
    start: true,
  },
];

export const tariffsCols = [
  {
    id: 1,
    key: 'name',
    label: 'Название тарифа',
    colStyle: { ...headStyle, width: '15%' },
    cellComponent: inputCell,
  },
  {
    id: 2,
    key: 'price',
    label: 'Цена',
    colStyle: { ...headStyle, width: '15%' },
    cellComponent: inputCell,
  },
  {
    id: 3,
    key: 'botsCount',
    label: 'Кол-во ботов',
    colStyle: { ...headStyle, width: '15%' },
    cellComponent: inputCell,
  },
  {
    id: 4,
    key: 'subscribersCount',
    label: 'Кол-во подписчиков',
    colStyle: { ...headStyle, width: '15%' },
    cellComponent: inputCell,
  },
  {
    id: 5,
    key: 'duration',
    label: 'Длительность',
    colStyle: { ...headStyle, width: '10%' },
    cellComponent: inputCell,
  },
  {
    id: 6,
    key: 'status',
    label: 'Опубликован',
    colStyle: { ...headStyle, width: '15%' },
    cellComponent: switcherCell,
  },
  {
    id: 7,
    key: 'isStarted',
    label: 'Стартовый тариф',
    colStyle: { ...headStyle, width: '15%' },
    cellComponent: switcherCell,
  },
];

export const tariffsTableModalButtons = [{ label: 'Удалить', value: 'del' }];
